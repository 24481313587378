import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './Login';
import Menu from './Menu';
import Procesos from './Procesos';
import Calculator from './Calculator';
import AlgaloopE24 from './AlgaloopE24';
import Layout from './Layout';
import './App.css';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState('');

  const handleLogout = () => {
    setAuthenticated(false);
    setUsername('');
  };

  return (
    <Router>
      <nav>
        <ul>
          <li>
            <Link to="/calculator">Calculadora</Link> {/* Enlace a la calculadora */}
          </li>
          <li>
            <Link to="/algaloop-e24">Algaloop E24</Link> {/* Enlace a Algaloop E24 */}
          </li>
          {authenticated ? (
            <>
              <li>
                <Link to="/menu">Menu</Link>
              </li>
              <li>
                <Link to="/procesos">Procesos</Link>
              </li>
              <li>
                <button onClick={handleLogout}>Logout</button>
              </li>
            </>
          ) : (
            <li>
              <Link to="/login">Login</Link>
            </li>
          )}
        </ul>
      </nav>
      <Routes>
        <Route path="/login" element={<Login setAuthenticated={setAuthenticated} setUsername={setUsername} />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/algaloop-e24" element={<AlgaloopE24 />} />
        {authenticated && (
          <>
            <Route path="/menu" element={<Menu />} />
            <Route path="/procesos" element={<Procesos />} />
          </>
        )}
      </Routes>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <img src="/Algaloop.png" alt="Algaloop Logo" style={{ width: '400px' }} />
      </div>
    </Router>
  );
}

export default App;