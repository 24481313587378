// Menu.js
import React from 'react';

function Menu({ username }) {
  return (
    <div className="container">
      <h1>Welcome, {username}!</h1>
    </div>
  );
}

export default Menu;