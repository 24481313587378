import React, { useState } from 'react';
import axios from 'axios';
import './Calculator.css'; // Importa el archivo CSS

const Calculator = () => {
  const [volumen, setVolumen] = useState('');
  const [microalga, setMicroalga] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [ingresoMensual, setIngresoMensual] = useState('');
  const [waterType, setWaterType] = useState('potable');
  const [result, setResult] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://94.23.204.59:3001/api/calculate', {
        volumen,
        microalga,
        cantidad,
        ingreso_mensual: ingresoMensual,
        waterType
      });
      setResult(response.data);
    } catch (error) {
      console.error('Error al calcular:', error);
    }
  };

  return (
    <div className="calculator-container">
      <h1>Calculadora</h1>
      <form onSubmit={handleSubmit}>
        <label>Volumen:</label>
        <input type="number" value={volumen} onChange={e => setVolumen(e.target.value)} />
        <br />
        <label>Microalga:</label>
        <select value={microalga} onChange={e => setMicroalga(e.target.value)}>
          <option value="">Select</option>
          <option value="spirulina">Spirulina</option>
          <option value="otro">Otro</option>
        </select>
        <br />
        <label>Cantidad:</label>
        <input type="number" value={cantidad} onChange={e => setCantidad(e.target.value)} />
        <br />
        <label>Ingreso Mensual:</label>
        <input type="number" value={ingresoMensual} onChange={e => setIngresoMensual(e.target.value)} />
        <br />
        <label>Tipo de Agua:</label>
        <select value={waterType} onChange={e => setWaterType(e.target.value)}>
          <option value="potable">Potable</option>
          <option value="restidual">Residual</option>
        </select>
        <br />
        <button type="submit">Calcular</button>
      </form>
      {result && (
        <div className="result-container">
          <h2>Resultado</h2>
          <img src={`data:image/png;base64,${result.img1}`} alt="Flujo de Efectivo" />
          <img src={`data:image/png;base64,${result.img2}`} alt="Crecimiento" />
          <h3>Detalles de Implementación</h3>
          <ul className="details-list">
            {Object.entries(result.implementacion_detail).map(([key, value]) => (
              <li key={key}><strong>{key}:</strong> {value}</li>
            ))}
          </ul>
          <h3>Detalles de Opex</h3>
          <ul className="details-list">
            {Object.entries(result.opex_detail).map(([key, value]) => (
              <li key={key}><strong>{key}:</strong> {value}</li>
            ))}
          </ul>
          <p><strong>Total Implementación:</strong> {result.total_implementacion}</p>
          <p><strong>Total Opex:</strong> {result.total_opex}</p>
          <p><strong>Ingreso Mensual:</strong> {result.ingreso_mensual}</p>
        </div>
      )}
    </div>
  );
};

export default Calculator;