import React from 'react';

const AlgaloopE24 = () => {
  const reportUrl = 'https://app.powerbi.com/view?r=eyJrIjoiOGE2YjliNDktOTZkNy00YTE1LTg0ODYtNTExYjRhNzQ1ZDE0IiwidCI6IjMwYTI5NmEzLWYzNTctNDY2Yi1hMmRiLWQ1NzkxMWRlYzBiNSIsImMiOjh9&pageName=6ca37076b03aa60103a1';

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src={reportUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
        title="Power BI Report"
      ></iframe>
    </div>
  );
};

export default AlgaloopE24;